import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { PhaseBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { IConfigSliderCookingStep, ISectionList, spilloneConfiguration, temperatureConfiguration } from 'src/app/core/cooking-step.utils';
import { PhaseControlTypeEnum } from 'src/app/core/cooking-step.utils';
import { PhaseSwitcher } from '../../../cooking-steps.handler';


@Component({
  selector: 'app-phase-tab-neo',
  templateUrl: './phase-tab-neo.component.html',
  styleUrls: ['./phase-tab-neo.component.scss']
})
export class PhaseTabNeoComponent implements OnInit, OnDestroy {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() phaseIndex: number;
  @Input() firstPhase: boolean = false;
  @Input() switcher: PhaseSwitcher;

  selectedTime: number = 10;
  sectionTypeList = PhaseControlTypeEnum;


  get tempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['cameraTemperature'] as UntypedFormControl;
  }
  get timeControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['timer'] as UntypedFormControl;
  }
  get spilloneTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['spilloneTemperature'] as UntypedFormControl;
  }
  get autoclimateControl() {
    return this.fgroup.controls['autoclimate'] as UntypedFormControl;
  }
  get fanTypeControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['type'] as UntypedFormControl;
  }
  get fanControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['speed'] as UntypedFormControl;
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  spilloneSetup: IConfigSliderCookingStep = {
    ceil: 150,
    floor: 1,
    minLimit: 1
  };

  timeSetup = {
    ceil: 86400,
    floor: 0,
    step: 60,
    time: true,
    firstStep: 60
  };
  
  autoclimateSetup = {
    ceil: 100,
    floor: 0,
    step: 1
  };

  fanSpedValues = [1,2,3,4,5]

  unsubscribe$: Subject<void> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.temperatureSetup = temperatureConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;
    this.spilloneSetup = spilloneConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;

    this.onTemperatureChage();
  }

  getTemperatureColor() {
    switch (this.tabType) {
      case 'SHOCK_FREEZING':
      case 'BLAST_CHILLING':
      case 'LEAVENING':
        return 'frozen';
      default:
        return 'red';
    }
  }

  getTemperatureImage() {
    switch (this.tabType) {
      case 'SHOCK_FREEZING':
      case 'BLAST_CHILLING':
      case 'LEAVENING':
        return 'assets/images/nabook/temp_snow.png';
      default:
        return 'assets/images/temp.png';
    }
  }

  showControl(sectionType: PhaseControlTypeEnum) {
    const control = this.sectionList.find((s) => s.label === sectionType );
    return control && ( control?.disabled === false || !(control?.disabled as number[]).includes( this.phaseIndex ) );
  }

  setUseSpillone( value: boolean ) {
    this.switcher.useSpillone = value;
  }

  onTemperatureChage() {
    this.tempControl.valueChanges.subscribe(event => {
      if ( this.spilloneSetup && this.spilloneTempControl.value < event ) {
        const spilloneValue = event + 2;
        this.spilloneTempControl.setValue(spilloneValue);
        this.spilloneSetup.floor = spilloneValue;
        this.spilloneSetup.minLimit = spilloneValue;
      }
    });
  }

}
