<div class="modal-header">
  <h2>
    {{
      (!phase
        ? 'COOKINGSTEP.CREATE_COOKING_STEP'
        : 'COOKINGSTEP.EDIT_COOKING_STEP'
      ) | translate
    }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" [formGroup]="cookingStepFormGroup">

  <ng-container id="phaseSteps" *ngIf="phase">
    <div id="step-list" *ngIf="cookingSteps.length !== 0">
      <button
        *ngFor="let item of cookingSteps; let i = index"
        [ngClass]="
          selected === i ? 'button-tertiary' : 'button-outline-tertiary'
        "
        (click)="selectStep(i)"
      >
        {{ 'COOKINGSTEP.STEP' | translate }} {{ (i+1) }}
      </button>
    </div>
  </ng-container>

  <div id="phaseEditor" class="phase-editor row w-100">
    <div id="display" class="display col-12 col-lg-6">
      <ng-container *ngIf="cookingMode === 'COMBI'">
        <div class="body display-border">
          <ul
          id="tabs"
          ngbNav
          #nav="ngbNav"
          [activeId]="phaseType"
          [destroyOnHide]="false"
          (navChange)="navChange($event)"
          class="nav-tabs"
        >
          <ng-container *ngFor="let tab of tabList">
            <li [ngbNavItem]="tab.ref" [destroyOnHide]="true">
              <a ngbNavLink>
                <img [src]="tab.ui.img.url" [alt]="tab.ui.img.alt" />
                <span> {{ tab.ui.label }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-phase-tab-combi
                  [deviceModel]="deviceModel"
                  [cookingMode]="cookingMode"
                  [sectionList]="tab.sectionList"
                  [fgroup]="cookingStepFormGroup"
                  [tabType]="tab.ref"
                  [tempUnit]="tempUnit"
                  [firstPhase]="firstPhase!"
                ></app-phase-tab-combi>
              </ng-template>
            </li>
          </ng-container>
          </ul>
          <div id="tabs-content" [ngbNavOutlet]="nav"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="cookingMode === 'HSO'">
        <div class="body display-border">
          <app-phase-tab-hso
            [deviceModel]="deviceModel"
            [cookingMode]="cookingMode"
            [phaseForm]="cookingStepFormGroup"
            [tempUnit]="tempUnit"
          ></app-phase-tab-hso>
        </div>
      </ng-container>
      <ng-container *ngIf="cookingMode === 'NABOO'">
        <div class="body display-border">
          <ul
          id="tabs"
          ngbNav
          #nav="ngbNav"
          [activeId]="phaseType"
          [destroyOnHide]="false"
          (navChange)="navChange($event)"
          class="nav-tabs"
        >
          <ng-container *ngFor="let tab of tabList">
            <li [ngbNavItem]="tab.ref" [destroyOnHide]="true">
              <a ngbNavLink>
                <img [src]="tab.ui.img.url" [alt]="tab.ui.img.alt" />
                <span> {{ tab.ui.label }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-phase-tab-naboo
                  [deviceModel]="deviceModel"
                  [cookingMode]="cookingMode"
                  [sectionList]="tab.sectionList"
                  [fgroup]="cookingStepFormGroup"
                  [switcher]="switcher"
                  [tabType]="tab.ref"
                  [tempUnit]="tempUnit"
                  [phaseIndex]="selected"
                  [firstPhase]="firstPhase!"
                  (setAirventListItems)="setAirventListItems($event)"
                ></app-phase-tab-naboo>
              </ng-template>
            </li>
          </ng-container>
          </ul>
          <div id="tabs-content" [ngbNavOutlet]="nav"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="cookingMode === 'NEO'">
        <div class="body display-border">
          <ul
          id="tabs"
          ngbNav
          #nav="ngbNav"
          [activeId]="phaseType"
          [destroyOnHide]="false"
          (navChange)="navChange($event)"
          class="nav-tabs"
        >
          <ng-container *ngFor="let tab of tabList">
            <li [ngbNavItem]="tab.ref" [destroyOnHide]="true">
              <a ngbNavLink>
                <img [src]="tab.ui.img.url" [alt]="tab.ui.img.alt" />
                <span> {{ tab.ui.label }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-phase-tab-neo
                  [deviceModel]="deviceModel"
                  [cookingMode]="cookingMode"
                  [sectionList]="tab.sectionList"
                  [fgroup]="cookingStepFormGroup"
                  [switcher]="switcher"
                  [tabType]="tab.ref"
                  [tempUnit]="tempUnit"
                  [phaseIndex]="selected"
                  [firstPhase]="firstPhase!"
                ></app-phase-tab-neo>
              </ng-template>
            </li>
          </ng-container>
          </ul>
          <div id="tabs-content" [ngbNavOutlet]="nav"></div>
        </div>

      </ng-container>
    </div>
   
    <div id="phasePlus" class="plus-box mt-3 col-12 col-lg-6">
      <div id="preheat" class="px-3 pb-3 single-el"
        *ngIf="showCommonPhaseSetupItem('PREHEAT')"
      >
        <span class="main-label captions">
          {{'COOKINGSTEP.PREHEAT' | translate}}
        </span>
        <ng-container *ngIf="preheatHandler.preheatEnabled">
          <div>
            <span *ngIf="preheatNote" class="note small">*{{ preheatNote | translate }}</span>
            <app-slider
              [control]="preheatTemperature"
              [floor]="preheatHandler.getPreheatFloorValue()"
              [ceil]="preheatConfiguration(deviceModel, cookingMode, tempUnit)!.ceil"
              [color]="'red'"
              [temperature]="true"
              [tempUnit]="tempUnit"
              [showButton]="true"
              [step]="1"
            ></app-slider>
          </div>
        </ng-container>
        <ng-container *ngIf="!preheatHandler.preheatEnabled">
          <div class="text-light">
            {{ 'GLOBAL.NULL_VALUE' | translate }}
          </div>
        </ng-container>
      </div>

      <div ngbAccordion #ngbAccordion>

        <div id="global" ngbAccordionItem>
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <div class="group-header">
                <img class="img" id="logo" src="assets/images/menu_icona_plus.png" alt="Plus" />
                <h2>{{ 'PHASE.PLUS' | translate }}</h2>
              </div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div>
  
                  <div id="holding" class="maintenance single-el"
                    *ngIf="showCommonPhaseSetupItem('HOLDING')"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.MAINTENANCE' | translate}}
                      </span>
                      <ng-select
                        [formControl]="typeHolding!"
                        [items]="holdingConfiguration.list"
                        classList="custom-input"
                        bindValue="name"
                        bindLabel="name"
                        [clearable]="false"
                        [searchable]="false"
                      ></ng-select>
                    </div>
                    <app-slider
                      *ngIf="typeHolding.value !== 'Off'"
                      [control]="temperatureHolding"
                      [floor]="holdingConfiguration.floor"
                      [ceil]="holdingConfiguration.ceil"
                      [color]="'red'"
                      [showButton]="true"
                      [step]="1"
                      [tempUnit]="tempUnit"
                      [temperature]="true"
                    ></app-slider>
                  </div>

                  <div id="finishing" class="maintenance single-el"
                    *ngIf="showCommonPhaseSetupItem('FINISHING')"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.FINISHING' | translate}}
                      </span>
                      <ng-select
                        [formControl]="finishingForm!"
                        [items]="finishingTypesList"
                        classList="custom-input"
                        bindValue="id"
                        bindLabel="name"
                        [clearable]="false"
                        [searchable]="false"
                      ></ng-select>
                    </div>
                  </div>

                  <div id="drying" class="maintenance single-el"
                    *ngIf="showCommonPhaseSetupItem('DRYING') && phaseType === 'SLOW_COOKING'"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.DRYING' | translate}}
                      </span>
                      <ng-select
                        [formControl]="dryingControl!"
                        [items]="onOffValues"
                        classList="custom-input"
                        bindValue="id"
                        bindLabel="name"
                        [clearable]="false"
                        [searchable]="false"
                      >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                          {{ item.name | translate }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>

                  <div id="softDry" class="maintenance single-el"
                    *ngIf="showSinglePhaseSetupItem('SOFT_DRY')"
                  >
                    <div class="header">
                      <div class="with-note">
                        <span class="main-label captions" >
                          {{'COOKINGSTEP.SOFT_DRY' | translate}}
                        </span>
                        <span class="note">*{{ 'COOKINGSTEP.ONLY_FOR_NABOO' | translate }}</span>
                      </div>
                      <ng-select
                        [formControl]="softDryControl"
                        [items]="onOffValues"
                        classList="custom-input"
                        bindValue="id"
                        bindLabel="name"
                        [clearable]="false"
                        [searchable]="false"
                      >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                          {{ item.name | translate }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>

                  <div id="airvent" class="maintenance single-el"
                    *ngIf="showSinglePhaseSetupItem('AIRVENT')"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.AIRVENT' | translate}}
                      </span>
                      <ng-select
                        [formControl]="airventType!"
                        [items]="airventTypesList"
                        classList="custom-input"
                        bindValue="id"
                        bindLabel="name"
                        [clearable]="false"
                        [searchable]="false"
                      >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                          {{ item.name | translate }}
                        </ng-template>
                      </ng-select>
                    </div>
                    <app-slider
                      *ngIf="airventType.value === 'PREOPENING'"
                      [control]="airventTime"
                      [floor]="60"
                      [ceil]="getAirventCeil()"
                      [color]="'orange'"
                      [showButton]="true"
                      [step]="60"
                      [time]="true"
                    ></app-slider>
                  </div>

                  <div id="moisturizing" class="maintenance single-el"
                    *ngIf="showSinglePhaseSetupItem('MOISTURIZING')"
                  >
                    <div class="header">
                      <div class="with-note">
                        <span class="main-label captions" >
                          {{'COOKINGSTEP.MOISTURIZING' | translate}}
                        </span>
                        <span class="note">*{{ 'COOKINGSTEP.ONLY_FOR_PUFF' | translate }}</span>
                      </div>
                      <app-dot-selector
                        [elements]="moisturizingValues"
                        [color]="'#f9b100'"
                        [firstAsOff]="true"
                        [control]="cookingStepFormGroup.get('moisturizing')!"
                      ></app-dot-selector>
                    </div>
                  </div>

                  <div id="aroma" class="maintenance single-el"
                    *ngIf="showSinglePhaseSetupItem('AROMA')"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.AROMA' | translate}}
                      </span>
                      <app-dot-selector
                        [elements]="aromaSmokeValues"
                        [color]="'#00afff'"
                        [firstAsOff]="true"
                        [control]="cookingStepFormGroup.get('aroma')!"
                      ></app-dot-selector>
                    </div>
                  </div>

                  <div id="smoke" class="maintenance single-el"
                    *ngIf="showSinglePhaseSetupItem('SMOKE')"
                  >
                    <div class="header">
                      <span class="main-label captions" >
                        {{'COOKINGSTEP.SMOKE' | translate}}
                      </span>
                      <app-dot-selector
                        [elements]="aromaSmokeValues"
                        [color]="'#00afff'"
                        [firstAsOff]="true"
                        [control]="cookingStepFormGroup.get('smokegrill')!"
                      ></app-dot-selector>
                    </div>
                  </div>

                  <div id="actions" class="single-el"
                    *ngIf="showSinglePhaseSetupItem('ACTIONS')"
                  >
                    <div class="pb-2">
                      <span class="main-label captions">
                        {{'COOKINGSTEP.COMMANDS' | translate}}
                      </span>
                    </div>
                    <div class="command-list" *ngIf="actionsList.length > 0">
                      <ng-container *ngFor="let action of actionsList">
                        <label for="{{ action.type }}"
                          ><input
                            type="checkbox"
                            name="commands"
                            id="{{ action.type }}"
                            [value]="action.type"
                            [checked]="action.checked"
                            (click)="setAction($event, action.type!)"
                          />
                          {{ action.name! | translate }}</label
                        ><br />
                      </ng-container>
                    </div>
                    <label class="custom-label mx-3" *ngIf="showCustomCommandTextArea()">
                      <textarea
                        class="custom-input"
                        rows="3"
                        [value]="customActionValue"
                        (change)="setCustomActionValue($event)"
                      ></textarea>
                    </label>
                  </div>

                </div>
              </ng-template>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<div class="modal-footer">
  <button
    [disabled]="!cookingStepFormGroup.valid"
    (click)="saveCookingStep()"
    class="button-primary"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>
