import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { UserService } from 'src/app/api/nbk';

@Component({
  selector: 'app-settings-pin-modal',
  templateUrl: './settings-pin-modal.component.html',
  styleUrls: ['./settings-pin-modal.component.scss']
})
export class SettingsPinModal implements OnInit, OnDestroy {
  @Input() pinAlreadyExists: boolean = false;

  @Output() 
  insertPin: EventEmitter<string> = new EventEmitter();
  
  @Output() 
  validPin: EventEmitter<void> = new EventEmitter();

  unsubscribe$: Subject<void> = new Subject();

  settingsPinFormGroup: UntypedFormGroup;

  constructor(
    private userService: UserService,
    public activeModal: NgbActiveModal ) 
  {
    this.settingsPinFormGroup = new UntypedFormGroup({
      password: new UntypedFormControl(''),
      confirmPassword: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    if (this.pinAlreadyExists) {
      this.settingsPinFormGroup.get('password')?.setValidators([Validators.required]);
    } else {
      this.settingsPinFormGroup.get('password')?.setValidators([Validators.required, Validators.minLength(6)]);
      this.settingsPinFormGroup.get('confirmPassword')?.setValidators([Validators.required, this.passwordMatchValidator()]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  passwordMatchValidator( ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.settingsPinFormGroup?.get('password')?.value;
      const confirmPassword = control.value;
      return password === confirmPassword ? null : { pinMismatch: true };
    };
  }

  handlePin() {
    if (this.settingsPinFormGroup.invalid) {
      return;
    }

    if ( this.pinAlreadyExists ) {
      this.userService
      .verifySettingsPin(this.settingsPinFormGroup.get('password')?.value)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.validPin.emit();
          this.activeModal.close();
        }),
        catchError((err) => {
          this.settingsPinFormGroup.get('password')?.setErrors({ wrongPin: true });
          return of({});
        }),
      ).subscribe();
    } else {
      this.insertPin.emit( this.settingsPinFormGroup.get('password')?.value );
      this.activeModal.close();
    }
    
  }

}
