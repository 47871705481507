import { Injectable } from '@angular/core';
import { PhaseBoDtoNBK, PreheatDtoNBK } from 'src/app/api/nbk';
import { preheatConfiguration, temperatureConfiguration, TTemperatureUnit, DevicePhasesConfig } from 'src/app/core/cooking-step.utils';
import { CookingModeEnum, DeviceModelEnum } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class PreheatHandlerService {

  private preheatInitialTemperature: number | null;
  private preheatFloorValue: number = 0;
  public preheatEnabled: boolean = false;

  constructor() {}

  getPreheatInitialTemperature() {
    return {
      type: this.preheatInitialTemperature !== null ? 'CONVECTION' : 'Off',
      temperature: this.preheatInitialTemperature !== null ? Math.round(this.preheatInitialTemperature) : undefined
    } ;
  }

  setPreheatInitialTemperature(value: number | undefined) {
    this.preheatInitialTemperature = value || 0;
  }

  getPreheatFloorValue() {
    return this.preheatFloorValue;
  }

  updatePreheatTemperature(
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum,
    temp: number,
    tempUnit: TTemperatureUnit
  ) {
    if ( this.preheatEnabled ) {
      const preheatConfig = preheatConfiguration(deviceModel,cookingMode,tempUnit);
      if ( preheatConfig && (cookingMode === 'COMBI' || cookingMode === 'NABOO')) {
        const temperatureWithPercentage = temp + temp * preheatConfig.percentageMinValue;
        this.preheatInitialTemperature =
          temperatureWithPercentage > preheatConfig.ceil
            ? preheatConfig.ceil
            : temperatureWithPercentage;
        this.preheatFloorValue = temp;
      }
    }
  }

  initPreheatTemperature(
    isFirst: boolean,
    resetPreheat: boolean, 
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum,
    cookingType: PhaseBoDtoNBK.CookingTypeEnum,
    phase: PhaseBoDtoNBK | null,
    preheat: PreheatDtoNBK,
    tempUnit: TTemperatureUnit
  ) {
    const preheatConfig = preheatConfiguration(deviceModel,cookingMode,tempUnit);
    const tempConfig = temperatureConfiguration(deviceModel,cookingMode,cookingType,tempUnit);
    this.preheatEnabled = this.isPreheatEnabled(isFirst, preheatConfig, tempConfig, preheat);

    if ( this.preheatEnabled ) {
      this.preheatFloorValue = this.initPreheatFloor(isFirst, deviceModel, cookingMode, cookingType, 
        preheatConfig, tempConfig, phase, tempUnit);

      if ( !isFirst || (phase && !resetPreheat) ) {
        this.preheatInitialTemperature = preheat.temperature!;
      } else {
        if (cookingMode === 'COMBI' ) {
          this.preheatInitialTemperature = tempConfig!.default +
            tempConfig!.default * preheatConfig!.percentageMinValue;
        } else if (cookingMode === 'HSO') {
          this.preheatInitialTemperature = preheatConfig!.defaultValue;
        } else if (cookingMode === 'NABOO') {
          this.preheatInitialTemperature = tempConfig!.default +
              tempConfig!.default * DevicePhasesConfig[deviceModel][cookingMode]!.preheat!.percentageMinValue;
        }
      }

    } else {
      this.resetValues();
    }
  }

  private resetValues() {
    this.preheatEnabled = false;
    this.preheatInitialTemperature = null;
    this.preheatFloorValue = 0;
  }

  private initPreheatFloor(
    isFirst: boolean,
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum,
    cookingType: PhaseBoDtoNBK.CookingTypeEnum,
    preheatConfig: any,
    tempConfig: any,
    phase: PhaseBoDtoNBK | null,
    tempUnit: TTemperatureUnit
  ) {

    if ( !isFirst ) {
      return preheatConfig.floor;
    }

    if (cookingMode === 'COMBI' ) {
      return phase && phase.temperature && phase.temperature > 0
        ? phase.temperature
        : tempConfig.floor;
    } else if (cookingMode === 'HSO') {
      return preheatConfig.floor;
    } else if (cookingMode === 'NABOO') {
      return this.getNabooPhaseTemperature(deviceModel, cookingMode, cookingType, tempUnit, phase);
    }

  }

  private isPreheatEnabled(
    isFirst: boolean,
    preheatConfig: any,
    tempConfig: any,
    preheat: PreheatDtoNBK
  ) {

    if ( isFirst ) {
      return preheatConfig !== undefined && tempConfig !== undefined;
    } else {
      return preheat && preheat.temperature !== null && preheat.temperature !== undefined; 
    }

  }

  private getNabooPhaseTemperature(
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum, 
    phaseType: PhaseBoDtoNBK.CookingTypeEnum,
    tempUnit: TTemperatureUnit, 
    phase: PhaseBoDtoNBK | null
  ) {
    let firstTemperature = temperatureConfiguration(deviceModel,cookingMode, phaseType, tempUnit)!.floor;
    if (phase && phase.cookingSettings) {
      if (phase.cookingSettings.cameraTemperature && phase.cookingSettings.cameraTemperature > 0) {
        firstTemperature = phase.cookingSettings.cameraTemperature;
      } else if (phase.cookingSettings.deltaTemperature && phase.cookingSettings.deltaTemperature > 0) {
        firstTemperature = phase.cookingSettings.deltaTemperature;

        if (phase.cookingSettings.spilloneTemperature && phase.cookingSettings.spilloneTemperature > 0) {
          firstTemperature += phase.cookingSettings.spilloneTemperature;
        }
      }
    }
    return firstTemperature;
  }

}
