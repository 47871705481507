import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { Subject } from 'rxjs';
import { PhaseActionBoDtoNBK, PhaseBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { TTemperatureUnit } from 'src/app/core/cooking-step.utils';
import { EditCookingStepsModalComponent } from './edit-cooking-steps-modal/edit-cooking-steps-modal.component';
import { disableTooltip } from 'src/app/core/utils';
import { CookingStepsHandler, PhaseSwitcher } from './cooking-steps.handler';

export type EditedPhase = {
  index?: number;
  formData: UntypedFormGroup;
  holdingData: UntypedFormGroup;
  preheatData: UntypedFormGroup;
  finishingData: UntypedFormControl;
  dryingData: UntypedFormControl;
  switcher: PhaseSwitcher;
}

@Component({
  selector: 'app-cooking-steps',
  templateUrl: './cooking-steps.component.html',
  styleUrls: ['./cooking-steps.component.scss']
})
export class CookingStepsComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {

  @Input() isEditing: boolean;
  @Input() isNew: boolean;
  @Input() multilevel: boolean;
  @Input() cookingStep: AbstractControl;
  @Input() preheat?: AbstractControl;
  @Input() holding?: AbstractControl;
  @Input() finishing?: AbstractControl;
  @Input() drying?: AbstractControl;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() tempUnit: TTemperatureUnit = 'C';

  @Output() resetPreheat: EventEmitter<void> = new EventEmitter();
  @Output() enableMultilevel: EventEmitter<boolean> = new EventEmitter();

  showNeoPhaseSelector = false;
  cookingSteps = new ClientFiltrableDataSource<PhaseBoDtoNBK>();
  columns: TableColumn[] = [];

  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;

  @ViewChild('editTpl', { static: true }) 
  editTpl: ElementRef<HTMLElement>;

  @ViewChild('idTpl', { static: true }) 
  idTpl: ElementRef<HTMLElement>;

  private unsubscribe$: Subject<void> = new Subject();

  get holdingType() {
    return (this.holding as UntypedFormGroup).controls['type'].value;
  }
  get holdingValue() {
    return (this.holding as UntypedFormGroup).controls['temperature'].value;
  }

  get preheatType() {
    return (this.preheat as UntypedFormGroup).controls['type'].value;
  }
  get preheatValue() {
    return (this.preheat as UntypedFormGroup).controls['temperature'].value;
  }

  constructor( 
    private ts: TranslateService, 
    private modalService: NgbModal, 
    private cdr: ChangeDetectorRef,
    private cookingStepsHandler: CookingStepsHandler
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.cookingStepsHandler.templates = {
      actionTpl: this.actionTpl,
      editTpl: this.editTpl,
      idTpl: this.idTpl
    };
    this.columns = this.cookingStepsHandler.getColumns(this.deviceModel, this.tempUnit);
    this.prepareSteps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes['multilevel'] ) {
      this.showNeoPhaseSelector = !changes['multilevel'].currentValue && !this.areTherePhases();
    }

    if ( changes['deviceModel'] ) {
      this.showNeoPhaseSelector = changes['deviceModel'].currentValue === 'NEO' && 
        this.isNew && !this.multilevel && !this.areTherePhases();
      this.columns = this.cookingStepsHandler.getColumns(this.deviceModel, this.tempUnit);
    }
  }


  prepareSteps() {
    this.cookingSteps.setItems(this.cookingStep.value || []);
  }

  private areTherePhases() {
    return this.cookingSteps.currentItems.length > 0;
  }

  editCookingStep(phase: PhaseBoDtoNBK, phaseIndex: number) {
    const modalRef = this.modalService.open(EditCookingStepsModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-xl'
    });

    modalRef.componentInstance.phase = phase;
    modalRef.componentInstance.firstPhase = phaseIndex === 0;
    modalRef.componentInstance.tempUnit = this.tempUnit;
    modalRef.componentInstance.cookingMode = this.cookingMode;
    modalRef.componentInstance.deviceModel = this.deviceModel;
    modalRef.componentInstance.selected = Number(phaseIndex);
    modalRef.componentInstance.phaseRecipeInitialValue = {
      preheat: this.preheat?.value,
      holding: this.holding?.value,
      finishing: this.finishing?.value,
      drying: this.drying?.value
    };
    modalRef.componentInstance.cookingSteps = this.cookingStep.value;
    modalRef.componentInstance.cookingStepsEdited.subscribe(
      (result: EditedPhase) => {
        modalRef.close();
        (this.cookingStep as UntypedFormArray).setControl(
          result.index!,
          result.formData
        );
        this.afterCookingStepEdit(result);
      }
    );
  }

  createNewStep() {
    const modalRef = this.modalService.open(EditCookingStepsModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-xl'
    });

    const isFirstPhase = this.cookingSteps.currentItems.length === 0;

    modalRef.componentInstance.firstPhase = isFirstPhase;
    modalRef.componentInstance.previusSavedPhaseType = 
      this.cookingStepsHandler.evaluetePreviusPhaseType(isFirstPhase,this.cookingSteps.currentItems);
    modalRef.componentInstance.phaseRecipeInitialValue = {
      preheat: this.preheat?.value,
      holding: this.holding?.value,
      finishing: this.finishing?.value,
      drying: this.drying?.value
    };
    modalRef.componentInstance.tempUnit = this.tempUnit;
    modalRef.componentInstance.cookingMode = this.cookingMode;
    modalRef.componentInstance.deviceModel = this.deviceModel;
    modalRef.componentInstance.cookingSteps = this.cookingStep.value;
    modalRef.componentInstance.cookingStepCreated.subscribe(
      (v: EditedPhase ) => {
        modalRef.close();

        if ( v.formData.controls['cookingType'].value === 'SLOW_COOKING' ) {
          this.addSlowCookingPhase(isFirstPhase, v);
        } else {
          (this.cookingStep as UntypedFormArray).push(v.formData);
          this.afterCookingStepEdit(v);
        }
      }
    );
  }

  private addSlowCookingPhase(isFirstPhase: boolean, v: EditedPhase) {
    if (isFirstPhase) {
      (this.cookingStep as UntypedFormArray).push(v.formData);

      if (!this.multilevel) {
        this.cookingStepsHandler.onSlowCookingSaving(this.cookingStep as UntypedFormArray,
          () => this.afterCookingStepEdit(v));
      } else {
        this.afterCookingStepEdit(v);
      }
    } else {
      (this.cookingStep as UntypedFormArray).insert(
        this.cookingStepsHandler.evalueteSlowCookingIndex(this.cookingSteps.currentItems),
        v.formData
      );
      this.afterCookingStepEdit(v);
    }
  }

  private afterCookingStepEdit (v: EditedPhase) {
    this.cookingStepsHandler.validatePhase( v.formData, v.switcher );
    this.prepareSteps();
    this.holding?.setValue(v.holdingData.value);
    if ( v.preheatData && v.preheatData.controls['temperature'].value === undefined ) {
      this.preheat?.reset();
    } else {
      this.preheat?.setValue(v.preheatData.value);
    }
    this.finishing?.setValue(v.finishingData.value);
    this.drying?.setValue(v.dryingData.value);

    this.onChangeSetMultilevel();
  }

  removeCookingStep(phaseIndex: string) {
    const index = parseInt(phaseIndex);
    (this.cookingStep as UntypedFormArray).removeAt(index);
    if (this.cookingStep.value.length === 0) {
      this.resetPreheat.emit();
    }
    this.prepareSteps();
    this.onChangeSetMultilevel();
  }

  onChangeSetMultilevel () {
    if ( this.deviceModel === 'NABOO' || this.deviceModel === 'NEO' ) {
      this.enableMultilevel.emit(!( this.cookingSteps.items && this.cookingSteps.items.length > 1))
    }
  }

  clearCookingStep() {
    this.cookingSteps.setItems([]);
    this.preheat?.reset();
    this.holding?.reset();
    this.finishing?.reset();
    this.drying?.reset();
    this.resetPreheat.emit();
  }

  setActionValue(actions: PhaseActionBoDtoNBK[]) : string {
    if ( actions ) {
      if ( actions.length == 1 && actions[0].type == 'CUSTOM') {
        return actions[0].customAction!;
      } else {
        return actions.map( a => this.ts.instant(`RECIPE.PHASE.ACTION.${a.type}`) ).join(', ');
      }
    }
    return '';
  }

  disableTooltip(el: HTMLSpanElement) {
    return disableTooltip(el);
  }

  canDelete(item: PhaseBoDtoNBK) {
    return this.isEditing && item.cookingType !== 'LEAVENING'
  }

  canCreate() {
    return this.isEditing && 
      (
        this.cookingSteps.items!.length === 0 || 
        ( !this.multilevel && this.cookingSteps.items![0].cookingType !== 'LEAVENING') 
      );
  }

  private resetPhases() {
    (this.cookingStep as UntypedFormArray).clear();
    this.clearCookingStep();
  }

  createLeaveningPhases() {
    this.resetPhases();
    this.cookingStepsHandler.createLeaveningPhasesForm((this.cookingStep as UntypedFormArray));
    this.prepareSteps();
    this.enableMultilevel.emit(false);
    this.hideNeoPhaseSelector();
  }

  resetNeoPhases() {
    this.resetPhases();
    this.enableMultilevel.emit(true);
    this.showNeoPhaseSelector = true;
  }

  hideNeoPhaseSelector() {
    this.showNeoPhaseSelector = false;
  }

}
