import { ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { TTemperatureUnit } from 'src/app/core/cooking-step.utils';
import { DeviceModelEnum } from 'src/app/core/utils';
import { TemperatureUnitPipe } from '../../pipes/temperature-unit.pipe';
import { TimeRangePipe } from '../../pipes/time-range.pipe';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { OptionsModalState } from '../../modals/options-modal/options-modal.component';
import { ModalService } from '../../modals/modal.service';
import { PhaseBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';

export interface PhaseSwitcher {
  useSpillone?: boolean,
  useDeltaTemperature?: boolean,
  useAlternateFan?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CookingStepsHandler {

  public templates: {
    actionTpl?: ElementRef<HTMLElement>,
    editTpl?: ElementRef<HTMLElement>,
    idTpl?: ElementRef<HTMLElement>
  } = {};

  constructor( 
    private ts: TranslateService, 
    private tempUnitPipe: TemperatureUnitPipe,
    private timeRangePipe: TimeRangePipe,
    private modalService: ModalService
  ) {}

  getColumns( deviceModel: DeviceModelEnum, tempUnit: TTemperatureUnit) {
    const starts = this.getCommonsStartColums();
    const ends = this.getCommonsEndColumns();
    let specific: TableColumn[] = [];

    switch (deviceModel) {
      case 'ORACLE':
        specific = this.getOracleColumns(tempUnit);
        break;
      case 'NABOO':
        specific = this.getNabooColumns(tempUnit);
        break;
      case 'NEO':
        specific = this.getNeoTables(tempUnit);
        break;
      }
      return [...starts, ...specific, ...ends];
  }

  onSlowCookingSaving( phasesForm: UntypedFormArray, afterChosen: () => void ) {
    const modalInfo: OptionsModalState = {
      title: this.ts.instant('COOKINGSTPEP.TYPES.SLOW_COOKING'),
      message: this.ts.instant('COOKINGSTPEP.SLOW_COOKING.HINT'),
      options: [
        {
          id: "NONE",
          name: "GLOBAL.NO"
        },
        {
          id: "BLAST_CHILLING",
          name: "COOKINGSTPEP.SLOW_COOKING.POSITIVE"
        },
        {
          id: "SHOCK_FREEZING",
          name: "COOKINGSTPEP.SLOW_COOKING.NEGATIVE"
        }
      ],
      preventClose: true
    };

    this.modalService.showOptionsModal(modalInfo).subscribe((r : any) => {
      switch ( r ) {
        case 'BLAST_CHILLING':
          this.addBlastChillingPhase(phasesForm);
          break;
        case 'SHOCK_FREEZING':
          this.addShockFreezingPhase(phasesForm);
          break;
        default:
          break;
      }
      afterChosen();
    });
  }

  evaluetePreviusPhaseType ( isFirstPhase:boolean, phases: PhaseBoDtoNBK[]) {
    if ( isFirstPhase ) {
      return undefined;
    }

    return phases[0].cookingType === 'SLOW_COOKING' 
            ? 'SLOW_COOKING' 
            : phases[phases.length -1].cookingType;
  }

  evalueteSlowCookingIndex ( phases: PhaseBoDtoNBK[]) {
    return phases[phases.length -1].cookingType !== 'SLOW_COOKING' 
            ? Math.max(phases.length - 1, 0)
            : phases.length;
  }

  createLeaveningPhasesForm( phasesForm: UntypedFormArray ) {
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('LEAVENING'),
      cookingSettings: new UntypedFormGroup({
        timer: new UntypedFormControl(14400),
        cameraTemperature: new UntypedFormControl(-5),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
    }));
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('LEAVENING'),
      cookingSettings: new UntypedFormGroup({
        cameraTemperature: new UntypedFormControl(0),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
    }));
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('LEAVENING'),
      cookingSettings: new UntypedFormGroup({
        timer: new UntypedFormControl(4200),
        cameraTemperature: new UntypedFormControl(10),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
      autoclimate: new UntypedFormControl(0)
    }));
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('LEAVENING'),
      cookingSettings: new UntypedFormGroup({
        timer: new UntypedFormControl(8400),
        cameraTemperature: new UntypedFormControl(25),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
      autoclimate: new UntypedFormControl(0)
    }));
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('LEAVENING'),
      cookingSettings: new UntypedFormGroup({
        cameraTemperature: new UntypedFormControl(25),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
      autoclimate: new UntypedFormControl(0)
    }));
  }

  validatePhase ( phaseForm : UntypedFormGroup, swithcher: PhaseSwitcher) {
    const notSwitcher = Object.values(swithcher).every(el => el === undefined);
    if ( notSwitcher ) {
      phaseForm.get('fan')?.patchValue({
        type: undefined,
        speed: undefined
      },{emit: false});
      phaseForm.get('cookingSettings')?.patchValue({
        timer: undefined,
        deltaTemperature: undefined,
        cameraTemperature: undefined,
        spilloneTemperature: undefined
      },{emit: false});
    } else {
      (phaseForm.controls['cookingSettings'] as UntypedFormGroup)
        .controls[ swithcher.useSpillone ? 'timer' : 'spilloneTemperature' ].setValue(undefined,{emit: false});

      (phaseForm.controls['cookingSettings'] as UntypedFormGroup)
        .controls[ swithcher.useDeltaTemperature ? 'cameraTemperature' : 'deltaTemperature' ].setValue(undefined,{emit: false});

      (phaseForm.controls['fan'] as UntypedFormGroup)
      .controls['type'].setValue(swithcher.useAlternateFan ? 'ALTERNATING' : 'NORMAL',{emit: false});
    }
  }

  definePhaseSettingsSwitcher ( deviceModel: RecipeBoDtoNBK.DeviceModelEnum, phase?: PhaseBoDtoNBK ) {
    const phaseSpillone = phase?.cookingSettings?.spilloneTemperature !== undefined;
    const phaseAlternateFan = phase?.fan?.type === 'ALTERNATING';
    const phaseDeltaT = phase?.cookingSettings?.deltaTemperature !== undefined;

    if ( deviceModel === 'NABOO' ) {
      return {
        useSpillone: phaseSpillone,
        useAlternateFan: phaseAlternateFan,
        useDeltaTemperature: phaseDeltaT 
      }
    } else if ( deviceModel === 'NEO' ) {
      return {
        useSpillone: phaseSpillone
      }
    }
    return {
      useSpillone: undefined,
      useDeltaTemperature: undefined,
      useAlternateFan: undefined
    };
  }

  private getCommonsStartColums(): TableColumn[] {
    return [
      {
        prop: 'id',
        name: this.ts.instant('COOKINGSTEP.CYCLES'),
        cellTemplate: this.templates.idTpl
      },
      {
        prop: 'cookingType',
        name: this.ts.instant('COOKINGSTEP.MODE'),
      }
    ]
  }

  private getCommonsEndColumns() {
    return [
      {
        maxWidth: 100,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate: this.templates.editTpl
      }
    ]
    
  }

  private getOracleColumns(tempUnit: TTemperatureUnit) : TableColumn[] {
    return [
      {
        prop: 'temperature',
        name: this.ts.instant('COOKINGSTEP.TEMPERATURE'),
        $$valueGetter: (e, prop) => this.tempUnitPipe.tempConverter( e.temperature, tempUnit)
      },
      {
        prop: 'magnetron',
        name: this.ts.instant('COOKINGSTEP.MICROWAVE'),
        $$valueGetter: (e, prop) => e.magnetron !== undefined ? `${e.magnetron}%` : "--"
      },
      {
        prop: 'time',
        name: this.ts.instant('COOKINGSTEP.TIMER'),
        $$valueGetter: (e, prop) => this.timeRangePipe.transform( e.time, tempUnit)
      },
      {
        prop: 'fanSpeed',
        name: this.ts.instant('COOKINGSTEP.VENTILATION'),
        $$valueGetter: (e, prop) => `${e.fanSpeed}%`
      },
      {
        prop: '',
        name: this.ts.instant('COOKINGSTEP.ACTION'),
        cellTemplate: this.templates.actionTpl
      },
    ];
  }

  private getNabooColumns(tempUnit: TTemperatureUnit) : TableColumn[] {
    return [...this.getNeoTables(tempUnit), 
      {
      prop: '',
      name: this.ts.instant('COOKINGSTEP.ACTION'),
      cellTemplate: this.templates.actionTpl
      } 
    ];
  }

  private getNeoTables(tempUnit: TTemperatureUnit) : TableColumn[] {
    return [
      {
        name: this.ts.instant('COOKINGSTEP.TEMPERATURE'),
        $$valueGetter: (e, prop) => {
          const temp  = e.cookingSettings.cameraTemperature  !== undefined ? 
              e.cookingSettings.cameraTemperature : e.cookingSettings.deltaTemperature;
          return this.tempUnitPipe.tempConverter( temp, tempUnit, true)
        }
      },
      {
        name: `${this.ts.instant('COOKINGSTEP.TIMER')}/${this.ts.instant('COOKINGSTEP.SPILLONE')}`,
        $$valueGetter: (e, prop) => {
          return e.cookingSettings.timer !== undefined 
            ? this.timeRangePipe.transform(e.cookingSettings.timer, tempUnit)
            : this.tempUnitPipe.tempConverter(e.cookingSettings.spilloneTemperature, tempUnit, true)
        }
      },
      {
        name: this.ts.instant('COOKINGSTEP.AUTOCLIMATE'),
        $$valueGetter: (e, prop) => {
          return e.autoclimate !== undefined && e.autoclimate !== null 
                  ? `${e.autoclimate}%`
                  : e.vaporType !== undefined && e.vaporType !== null 
                      ? e.vaporType 
                      : "--"
        }
      },
      {
        name: this.ts.instant('COOKINGSTEP.VENTILATION'),
        $$valueGetter: (e, prop) => e.fan.speed
      },
    ];
  }

  private addBlastChillingPhase( phasesForm: UntypedFormArray ) {
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('BLAST_CHILLING'),
      cookingSettings: new UntypedFormGroup({
        spilloneTempeature: new UntypedFormControl(10),
        cameraTemperature: new UntypedFormControl(0),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
    }));
  }

  private addShockFreezingPhase( phasesForm: UntypedFormArray ) {
    phasesForm.push(  new UntypedFormGroup({
      phaseType: new UntypedFormControl('NEO'),
      cookingType: new UntypedFormControl('SHOCK_FREEZING'),
      cookingSettings: new UntypedFormGroup({
        spilloneTempeature: new UntypedFormControl(3),
        cameraTemperature: new UntypedFormControl(-10),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(5)
      }),
    }));
  }

}
