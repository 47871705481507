import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { deltaTempConfiguration, EC0_VAPOR_TYPE_TRESHOLD, IConfigSliderCookingStep, ISectionList,
  MOISTURIZING_FLOOR,
  spilloneConfiguration, TEMP_TRESHOLD_NABOO, temperatureConfiguration, 
  TURBO_VAPOR_TYPE_TRESHOLD} from 'src/app/core/cooking-step.utils';
import { PhaseControlTypeEnum } from 'src/app/core/cooking-step.utils';
import { PhaseSwitcher } from '../../../cooking-steps.handler';
import { CookingModeEnum, DeviceModelEnum } from 'src/app/core/utils';
import { AirventDtoNBK, PhaseBoDtoNBK } from 'src/app/api/nbk';


@Component({
  selector: 'app-phase-tab-naboo',
  templateUrl: './phase-tab-naboo.component.html',
  styleUrls: ['./phase-tab-naboo.component.scss']
})
export class PhaseTabNabooComponent implements OnInit, OnDestroy {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: CookingModeEnum;
  @Input() deviceModel: DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() phaseIndex: number;
  @Input() firstPhase: boolean = false;
  @Input() switcher: PhaseSwitcher;

  @Output() setAirventListItems: EventEmitter<boolean> = new EventEmitter();

  onlyForNaboo = false;

  sectionTypeList = PhaseControlTypeEnum;


  get tempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['cameraTemperature'] as UntypedFormControl;
  }
  get deltaTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['deltaTemperature'] as UntypedFormControl;
  }
  get timeControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['timer'] as UntypedFormControl;
  }
  get spilloneTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['spilloneTemperature'] as UntypedFormControl;
  }
  get autoclimateControl() {
    return this.fgroup.controls['autoclimate'] as UntypedFormControl;
  }
  get vaporTypeControl() {
    return this.fgroup.controls['vaporType'] as UntypedFormControl;
  }
  get fanTypeControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['type'] as UntypedFormControl;
  }
  get fanControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['speed'] as UntypedFormControl;
    
  }
  get vaporControl() {
    return this.fgroup.controls['vaporType'] as UntypedFormGroup;
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  deltaTempSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  spilloneSetup: IConfigSliderCookingStep = {
    ceil: 150,
    floor: 1,
    minLimit: 1
  };

  timeSetup = {
    ceil: 86400,
    floor: 0,
    step: 10,
    time: true,
    firstStep: 60
  };
  
  autoclimateSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15
  };

  vaporTypeValues = [
    {
      src: 'assets/images/nabook/btn_ecovapor.png',
      value: 'ECO',
      disabled: false
    }, 
    {
      src: 'assets/images/nabook/btn_turbovapor.png',
      value: 'TURBO',
      disabled: true
    }
  ]

  fanSpedValues = [1,2,3,4,5,6]

  unsubscribe$: Subject<void> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.temperatureSetup = temperatureConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;
    this.spilloneSetup = spilloneConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;
    this.deltaTempSetup = deltaTempConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;

    this.onDeltaTemperatureChage();
    this.onTemperatureChage();
    this.onSpilloneChage();
    this.onlyForNabooHandler(this.tempControl.value || this.deltaTempControl.value);
    this.vaporTypeHandler(this.tempControl.value || this.deltaTempControl.value);

    this.onAirventChange(this.fgroup.controls['airvent'].value);
    this.fgroup.controls['airvent'].valueChanges.subscribe((value: AirventDtoNBK) => {
      this.onAirventChange(value);
    });

    this.fgroup.controls['aroma'].valueChanges.subscribe((value: any) => {
      this.onAromaChange(value);  
    });

    this.fgroup.controls['smokegrill'].valueChanges.subscribe((value: any) => {
      this.onSmokegrillchange(value);
    });
    
    this.onAutoclimateChange(this.fgroup.controls['autoclimate'].value);
    this.fgroup.controls['autoclimate'].valueChanges.subscribe((value: number) => {
      this.onAutoclimateChange(value);
    });

  }

  showControl(sectionType: PhaseControlTypeEnum) {
    const control = this.sectionList.find((s) => s.label === sectionType );
    return control && ( control?.disabled === false ||
      ( Array.isArray(control.disabled) && !(control?.disabled as number[]).includes( this.phaseIndex ) ) );
  }

  setUseSpillone( value: boolean ) {
    this.switcher.useSpillone = value;
    if ( !value ) {
      this.switcher.useDeltaTemperature = false;
    }
    this.airventHandler(value);
    this.moisturizingHandler();
  }

  setUseDeltaTemperature( value: boolean ) {
    this.switcher.useDeltaTemperature = value;
    const temp = value ? this.deltaTempControl.value : this.tempControl.value;
    this.vaporTypeHandler(temp);
    this.onlyForNabooHandler(temp);
    this.moisturizingHandler();
  }

  setUseAlternateFan( value: boolean ) {
    this.switcher.useAlternateFan = value;
    this.fanTypeControl.setValue( value ? 'NORMAL' : 'ALTERNATING',{ emitEvent: false } );
  }

  private onTemperatureChage() {
    this.tempControl.valueChanges.subscribe((value: number) => {
      if ( this.spilloneTempControl.value < value ) {
        const spilloneValue = value + 2;
        if ( spilloneValue > this.spilloneSetup.ceil!  ) {
          this.spilloneTempControl.setValue(this.spilloneSetup.ceil);
        } else {
          this.spilloneTempControl.setValue(spilloneValue);
          this.spilloneSetup.floor = spilloneValue;
          this.spilloneSetup.minLimit = spilloneValue;
        }
      }
      console.log(`tempValue: ${value}, spilloneValue: ${this.spilloneTempControl.value}`);
  
      this.onlyForNabooHandler(value);
      this.vaporTypeHandler(value);
      this.moisturizingHandler();
    });
  }

  private onDeltaTemperatureChage() {
    this.deltaTempControl.valueChanges.subscribe((value: number) => {
      this.vaporTypeHandler(value);
      this.onlyForNabooHandler(value);
      this.moisturizingHandler();
    })
  }

  onSpilloneChage() {
    this.spilloneTempControl.valueChanges.subscribe((value: number) => {
      this.moisturizingHandler();
    });
  }

  private airventHandler(useSpillone: boolean) {
    if ( this.tabType === 'CONVECTION' ) {
      this.setAirventListItems.emit(useSpillone);
      if ( useSpillone && this.fgroup.controls['airvent'].value.type === 'PREOPENING'  ) {
        this.fgroup.controls['airvent'].setValue({
          type: 'CLOSE',
          time: 0
        });
      }
    } else {
      this.fgroup.controls['airvent'].setValue(undefined);
    }
  }

  private vaporTypeHandler(event: any) {
    if ( this.tabType === 'STEAM' ) {
      if (event > TURBO_VAPOR_TYPE_TRESHOLD) {
        this.fgroup.controls['vaporType'].setValue('TURBO');
        this.vaporTypeValues.forEach(e => e.disabled = e.value === 'ECO');
      } else if ( event < EC0_VAPOR_TYPE_TRESHOLD ) {
        this.fgroup.controls['vaporType'].setValue('ECO');
        this.vaporTypeValues.forEach(e => e.disabled = e.value === 'TURBO');
      } else {
        this.fgroup.controls['vaporType'].setValue('TURBO');
        this.vaporTypeValues.forEach( e => e.disabled = false );
      }
    } else {
      this.fgroup.controls['vaporType'].setValue(undefined);
    }
  }

  private onlyForNabooHandler(event: any) {
    this.onlyForNaboo = event > TEMP_TRESHOLD_NABOO;
  }

  private moisturizingHandler () {
    if ( this.tabType === 'CONVECTION' ) {
      const temp : number = this.switcher.useSpillone && this.switcher.useDeltaTemperature  
        ? (this.deltaTempControl.value + this.spilloneTempControl.value)
        : this.tempControl.value ;
      if ( temp >= MOISTURIZING_FLOOR && temp <= TEMP_TRESHOLD_NABOO ) {
        this.fgroup.controls['moisturizing'].enable();
      } else {
        this.fgroup.controls['moisturizing'].disable();
        this.fgroup.controls['moisturizing'].setValue(undefined);
      }
    } else {
      this.fgroup.controls['moisturizing'].setValue(undefined);
    }
  }

  private onAirventChange(value: AirventDtoNBK) {
    if ( this.tabType === 'CONVECTION' ) {
      if ( value.type === 'CLOSE' ) {
        if ( this.fgroup.controls['aroma'].value === 'OFF' && 
          this.fgroup.controls['smokegrill'].value === 'OFF' ) {
          this.fgroup.controls['aroma'].enable({emitEvent: false});
          this.fgroup.controls['smokegrill'].enable({emitEvent: false});
        } else if ( this.fgroup.controls['smokegrill'].value !== 'OFF' ) {
          this.fgroup.controls['smokegrill'].enable({emitEvent: false});
        } else {
          this.fgroup.controls['aroma'].enable({emitEvent: false});
        }
      } else {
        this.fgroup.controls['aroma'].disable({emitEvent: false});
        this.fgroup.controls['smokegrill'].disable({emitEvent: false});
        this.fgroup.controls['aroma'].setValue('OFF',{emitEvent: false});
        this.fgroup.controls['smokegrill'].setValue('OFF',{emitEvent: false});
      }
    } else {
      this.fgroup.controls['aroma'].setValue(undefined,{emitEvent: false});
      this.fgroup.controls['smokegrill'].setValue(undefined,{emitEvent: false});
    }
  }

  private onAromaChange(value: any) {
    if ( value === 'OFF' ) {
      this.fgroup.controls['smokegrill'].enable({emitEvent: false});
    } else {
      this.fgroup.controls['smokegrill'].disable({emitEvent: false});
      this.fgroup.controls['smokegrill'].setValue('OFF',{emitEvent: false});
    }
  }

  private onSmokegrillchange(value: any) {
    if ( value === 'OFF' ) {
      this.fgroup.controls['aroma'].enable({emitEvent: false});
    } else {
      this.fgroup.controls['aroma'].disable({emitEvent: false});
      this.fgroup.controls['aroma'].setValue('OFF',{emitEvent: false});
    }
  }

  private onAutoclimateChange(value: number) {
    if ( this.tabType === 'CONVECTION' ) {
      if (value > 0) {
        this.fgroup.controls['softDry'].disable();
        this.fgroup.controls['softDry'].setValue(false);
      } else {
        this.fgroup.controls['softDry'].enable();
      }
    } else {
      this.fgroup.controls['softDry'].setValue(undefined);
    }
  }

}
